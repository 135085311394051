*, *:after, *:before {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root, .app {
  height: 100%;
  width: 100%;
}

#fg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

#bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

